import products from "./products"
const state = Object.keys(products).map((key, value) => (
    {
        name: products[key].name,
        count: 0,
        id: key,
        key: key,
        picture: products[key].picture,
        price: products[key].price,   // Add the actual price here
        taste: products[key].taste,   // Add the actual price here
        description: products[key].description,   // Add the actual price here
        usb: products[key].usb,   // Add the actual price here
        concentrate: products[key].concentrate,
        volume: products[key].volume,   // Add the actual price here

    }
))

export default state