import { Card, Container, Row, Col, Badge } from 'react-bootstrap';
import CountPill from './CountPill';
import products from '../catalog/products';
import isTelegram from '../components/telegram/isTelegram'
const HIGH_TASTE = 'Sativa'


export default function ProductCard({ id, product, addProduct, rmProduct }) {
    const productData = products['prod' + id];

    if (!productData) {
        // Handle the case where the product with the given `id` is not found
        return <div>Product not found</div>;
    }

    // Access the "picture" property from the product data


    const imageLink = require(`./../pics/${productData.picture}`)

    return (
        <Col>
            <Card className='seed-card'>
                <CountPill product={product} id={id} />
                <Card.Img variant="top" src={imageLink} />
                <Card.Body>
                    <Card.Title>{product.name}</Card.Title>
                    <Card.Text style={{padding: 0, fontSize: '1.2rem', fontWeight: 'bold'}}>Ціна: {product.price} грн.</Card.Text>
                    {product.description && <Card.Text style={{padding: 0}}>{product.description}</Card.Text>}
                    <Card.Text>
                        {product.usb && <Badge bg="primary">USB</Badge>} {product.taste && <Badge bg={product.taste === HIGH_TASTE ? 'success' : 'danger' }>{product.taste}</Badge>} <Badge bg="dark">HHC: {product.concentrate}%</Badge> <Badge bg="warning" text="dark">THC: 0.0%</Badge> <Badge bg="danger">{product.volume}</Badge>
                    </Card.Text>
                    <Container>
                        <Row>
                            <Col className='d-flex justify-content-between'>
                                {product.count > 0 && <button className="Button btn-block" onClick={() => rmProduct(id)}>🗑️</button>}
                                {product.count === 0 && <button className="Button btn-block" onClick={() => window.location.href='https://t.me/mr_vanesko'}>👋 ІНФО</button>}
                                {isTelegram && <button className="Button btn-block" style={{fontSize: '1.3rem'}} onClick={() => addProduct(id)}>&nbsp;+&nbsp;</button>}
                                {/* <a className='Button' href='https://t.me/vvapesbot?start=6ntp'>🤖ЗАМОВИТИ</a> */}
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Col>
    )
}
