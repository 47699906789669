import { Badge, Container, ListGroup, Image, Row, Col } from "react-bootstrap";
import orderPic from './order_vape_bg.png'
import React from 'react';
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import Puff from './Puff'


export default function Order({ order }) {
    return (
        <>
            {order.length > 0 && (
                <Container fluid style={{ padding: '10px' }}>

                    <h4>Ваше замовлення</h4>
                    <Row xs={1} md={1} className="g-1">
                        <ListGroup as="ol">
                            <TransitionGroup className="order-list">
                                {order.map(i => (
                                    <CSSTransition
                                        key={i.id}
                                        timeout={500}
                                        classNames="item"
                                    >
                                        <ListGroup.Item as="li"
                                            className="d-flex justify-content-between align-items-start">
                                            <Image style={{ width: 25, height: 25 }} src={orderPic} height="1rem" />
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{i.name} x {i.count}</div>
                                            </div>
                                            <Badge bg="primary" pill>{i.count * i.price}.00 грн.</Badge>
                                        </ListGroup.Item>
                                    </CSSTransition>
                                ))}
                            </TransitionGroup>
                        </ListGroup>
                    </Row>
                </Container>)}
        </>
    );
}