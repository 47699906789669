const products =  {
        "prod0": {
            "name": "Kyiv Vape + POD 2 ML. HHC 96% ",
            "picture": "elf.png",
            "price": 2500,   
            "taste": 'Sativa',
            "description": "Потужний випарювач! Тільки для професійних курців 💪💨🔝",
            "usb": true, 
            "concentrate": 96,   
            "volume": '2 ml.',   
            // Add other attributes as needed
        },
        
        "prod1": {
            "name": "POD 2 ML. HHC 96% ",
            "picture": "pod2.png",
            "price": 2000,   
            "taste": 'Sativa',   
            "description": "Скурив картридж до Kyiv Vape? Заміни новим! 😊💨🔄",
            "usb": false,   
            "concentrate": 96,
            "volume": '2 ml.',  
        },
        "prod2": {
            "name": "1 вейп-ручка + 1 HHC Картридж",
            "picture": "pack.png",
            "price": 2500,   // Add the actual price here
            "taste":'Sativa',   // Add the actual price here
            "description": "Повний комплект зі звичайним керамічним випарювачем під 510 різьбу 👨‍🔧",
            "usb": true,   // Add the actual price here
            "concentrate": 99,
            "volume": '1.3 ml.',   // Add the actual price here
            // Add other attributes as needed
        },
        "prod3": {
            "name": "1 HHC Картридж",
            "picture": "pod1.png",
            "price": 1500,   // Add the actual price here
            "taste": 'Sativa',   // Add the actual price here
            "description": "Змінний картридж для комплекту під 510 різьбу. Заправлений доверху! 😊👍",
            "usb": false,   // Add the actual price here
            "concentrate": 99,
            "volume": '1.3 ml.',   // Add the actual price here

            // Add other attributes as needed
        },
        // "prod4": {
        //     "name": "Вейп-картридж - Strawberry",
        //     "picture": "strawberry_1.webp",
        //     "price": 1700,   
        //     "taste": 'Indica',   
        //     "usb": false,   
        //     "concentrate": 95,   
        //     "volume": '1 ml.',   

            
        // },
        
        // "prod5": {
        //     "name": "Вейп-картридж - Amnesia Haze",
        //     "picture": "amnesia_haze_1.webp",
        //     "price": 1700,   
        //     "taste": 'Sativa',  
        //     "usb": true,   
        //     "concentrate": 96,
        //     "volume": '1 ml.',  
        // },
        
    }    

export default products