import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
// window.Telegram.WebApp.MainButton.isVisible = true
// window.Telegram.WebApp.onEvent('mainButtonClicked', function(e) {
  
  

//   let req = new XMLHttpRequest();

//   req.onreadystatechange = () => {
//     if (req.readyState == XMLHttpRequest.DONE) {
//       console.log(req.responseText);
//     }
//   };
    
//   req.open("POST", "https://api.jsonbin.io/v3/b", true);
//   req.setRequestHeader("Content-Type", "application/json");
//   req.setRequestHeader("X-Master-Key", "$2b$10$9qy/HesCArDhJ/IgBnobROhtxXyaMf6E.c309wU0oO6Co1d2GXQne");
//   req.send('{"sample": "Hello World Order From index.js"}');
//   window.Telegram.WebApp.close()
//   window.Telegram.WebApp.sendData('Замовлення прийнято!')
// })
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
