class HapticFeedback {
    constructor() {
        this.Telegram = window.Telegram;
    }

    notificationOccurred(type) {
        if (this.Telegram && this.Telegram.WebApp && this.Telegram.WebApp.HapticFeedback) {
            this.Telegram.WebApp.HapticFeedback.notificationOccurred(type);
        }
    }

    impactOccurred(type) {
        if (this.Telegram && this.Telegram.WebApp && this.Telegram.WebApp.HapticFeedback) {
            this.Telegram.WebApp.HapticFeedback.impactOccurred(type);
        }
    }
}
export default HapticFeedback;