import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import Shop from './Shop';
import Order from './Order';
// import Puff from './Puff';
import state from './catalog/state';
import HapticFeedback from './components/telegram/HapticFeedback';



function App() {

  const [order, updateOrdr] = useState(state);

  window.Telegram.WebApp.onEvent('mainButtonClicked', function (e) {
    window.Telegram.WebApp.MainButton.showProgress()
    // let req = new XMLHttpRequest();

    // req.onreadystatechange = () => {
      // console.log(order)
      // if (req.readyState === XMLHttpRequest.DONE) {
        window.Telegram.WebApp.sendData(order)
      // }
    // };
  })

  const addProduct = (p) => {
    const hapticFeedback = new HapticFeedback()
    hapticFeedback.impactOccurred('medium')

    order[p].count = order[p].count + 1
    updateOrdr(ordr => [...ordr]) //little hack

    const orderTotal = order.reduce((total, item) => total = total + (item.count * item.price), 0)

    if (orderTotal > 0) {
      window.Telegram.WebApp.MainButton.isVisible = true
      window.Telegram.WebApp.MainButton.text = `СУМА ЗАМОВЛЕННЯ: ${orderTotal} грн.`
    } else {
      window.Telegram.WebApp.MainButton.isVisible = false
    }
  }
  const rmProduct = (p) => {
    const hapticFeedback = new HapticFeedback()
    hapticFeedback.notificationOccurred('success')

    order[p].count = 0
    updateOrdr(ordr => [...ordr])
    if (order.map(it => it > 0).length > 0) {
      window.Telegram.WebApp.MainButton.isVisible = true
      window.Telegram.WebApp.MainButton.text = 'СУМА ЗАМОВЛЕННЯ: ' + order.reduce((total, item) => total = total + (item.count * item.price), 0) + ' грн.'
    } else {
      window.Telegram.WebApp.MainButton.isVisible = false
    }
  }

  return (
    <div className="App">
      <Order order={order.filter(i => i.count > 0)} />
      <Shop addProduct={addProduct} rmProduct={rmProduct} products={order} />
    </div>
  );
}

export default App;
