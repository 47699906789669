import React from 'react';

const isTelegram = () => {
  const isTelegramUser =
    window.Telegram &&
    window.Telegram.WebApp &&
    window.Telegram.WebApp.platform &&
    window.Telegram.WebApp.platform !== "unknown";

  return isTelegramUser ? true : null;
};

export default isTelegram();
